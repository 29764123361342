<template>
  <div style="height: 800px">
    <div style="width: 100%;height: 900px" id="map"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import bmapStyle from './bmap-style-dark'

  export default {
    data(){
      return {
        myChart:"",
      }
    },
    created() {
    },
    mounted() {
      this.myChart = echarts.init(document.getElementById('map'));
    },

    methods:{
      update(onlineData,offlineData){
        this.myChart.setOption({
          title: {
            text: '',
            left: 'center',
            top:"15px",
            textStyle:{
              color:"#FFF"
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: (params,ticket)=>{
              let device = params.data
              let status = device.online?"在线":"离线"
              return `${device.nickname}<br/>设备状态：${status}<br/>设备编号：${device.mac}<br/>地址：${device.address}`
            }
          },
          bmap: {
            center: [104.114129, 35.550339],
            zoom: 5,
            roam: true,
            mapStyle: bmapStyle
          },
          series : [
            {
              name: '离线设备',
              type: 'scatter',
              coordinateSystem: 'bmap',
              data: offlineData,
              symbolSize: 12,
              encode: {
                value: 2
              },
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              itemStyle: {
                color: "#FFF",
                shadowBlur: 2,
                shadowColor: "#000"
              },
              zlevel: 1
            },
            {
              name: '在线设备',
              type: 'effectScatter',
              coordinateSystem: 'bmap',
              data: onlineData,
              symbolSize: 10,
              encode: {
                value: 2
              },
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                // number:3,
                scale:3.5,
                // period:3
              },
              hoverAnimation: true,
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              itemStyle: {
                color: "#27d85a",
                // shadowBlur: 10,
                // shadowColor: '#333'
              },
              zlevel: 2
            }
          ]
        });
      }
    }
  }
</script>

<style scoped>
  .anchorBL{
    display: none !important;
  }
</style>
